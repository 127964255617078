.container {
  width: 100%;
  height: 235px;
  //   background: #fff;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  row-gap: 20px;
  column-gap: 20px;
  grid-template-areas:
    "num num chart"
    "num num chart";
}
.num {
  background: #fff;
}

.chart {
  background: #fff;
  grid-area: chart;
}

@primary-color: #3D8AFF;