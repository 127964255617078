.sim-list-container {
  display: flex;
  flex-direction: column;
}

.sim-list-form-wrap {
  //border: 1px solid red;
  //height: 200px;
  padding-bottom: 10px;
}

.sim-list-table {
  flex: 1;
  //border: 1px solid red;
}

@primary-color: #3D8AFF;