@import "./theme/theme.less";
.App {
  text-align: center;
}

// 通用部分逻辑
.common-container {
  //border: 1px solid red;
  height: 100%;
  height: 100%;
}

@primary-color: #3D8AFF;