.logo{
  width: 200px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-layout-background{
  //display: flex;
  //flex-direction: row-reverse;
}

@primary-color: #3D8AFF;